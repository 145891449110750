<template>
    <button :type="type"
            class="inline-flex items-center px-4 py-2 bg-primary border border-transparent
                   font-bold text-xs text-white uppercase tracking-widest hover:bg-on-primary
                   active:bg-on-primary focus:outline-none focus:border-gray-900 focus:shadow-outline-gray
                   transition ease-in-out duration-150">
        <slot/>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'submit',
        },
    }
}
</script>
